<template>
  <v-container>
    <!-- Pantalla si no existen empleados aún -->
    <v-row
      v-if="isEmployeesEmpty"
      justify="center"
      class="d-flex justify-center align-center text-center"
      style="min-height: 80vh"
    >
      <v-col cols="12" md="8" class="px-10">
        <v-img
          src="@/assets/employee.png"
          contain
          max-height="120"
          alt="Store"
          class="mb-3"
        ></v-img>

        <p class="text-body-2 my-5 px-8">
          Da de alta tus empleados para poder asignarlos a las diferentes
          sucursales.
        </p>

        <v-btn
          rounded
          block
          outlined
          color="primary"
          class="py-6 border-3 text-none text-body-1 font-weight-bold"
          style="border-width: 3px"
          @click="addEmployee"
        >
          <v-icon left>mdi-plus</v-icon>
          Agregar empleado
        </v-btn>
      </v-col>
    </v-row>

    <!-- Empleados existentes -->
    <v-row>
      <div v-if="!isEmployeesEmpty" class="mt-5 px-5" style="width: 100%">
        <v-col
          v-if="employees.length <= employeeLimit"
          cols="12"
          md="4"
          class="d-flex"
        >
          <v-btn
            rounded
            block
            outlined
            color="primary"
            class="py-6 border-3 text-none text-body-1 font-weight-bold"
            style="border-width: 3px"
            @click="addEmployee"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar empleado
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex"
          v-for="(employee, index) in employees"
          :key="index"
        >
          <v-card class="pa-5 bgcards rounded-xl" style="width: 100%">
            <v-card-title class="d-flex justify-space-between align-center">
              <h3 class="text-h6 font-weight-bold">Empleado {{ index + 1 }}</h3>
              <v-btn
                text
                class="font-weight-bold primary--text text-none"
                @click="goToEdit(employee.id, index + 1)"
              >
                Editar
              </v-btn>
                <p v-if="employee.otp" class="text-caption grey--text">
                Codigo de verificacion: {{ employee.otp }}
              </p>
            </v-card-title>

            <v-card-text class="text-start black--text">
              <div>
                <v-row>
                  <!-- Información general del empleado -->
                  <v-col cols="12">
                    <p>
                      Nombre completo <br /><strong>{{
                        employee.UsuarioDetalle?.nombre
                      }}</strong>
                    </p>
                    <p>
                      Celular de contacto <br /><strong>{{
                        employee.telefono
                      }}</strong>
                    </p>
                    <p>
                      Rol <br /><strong>{{
                        SustituirRol(employee.rol_id)
                      }}</strong>
                    </p>
                  </v-col>
                </v-row>
                <hr
                  class="black--text"
                  style="border-color: black; border-width: 0.5px"
                />
                <!-- Lista de sucursales -->
                <div
                  class="mt-4"
                  v-for="(branch, empIndex) in employee.Sucursales"
                  :key="empIndex"
                >
                  <v-row>
                    <v-col cols="6">
                      <p>
                        Sucursal <br /><strong>{{ branch?.nombre }}</strong>
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <p>
                        Estado <br />
                        <strong>{{
                          branch?.UsuarioSucursal?.es_activo ? "Activo" : "Inactivo"
                        }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                  <p>
                    Límite de gastos en PAT <br />
                    <strong>{{ branch.UsuarioSucursal.limite_compra }}</strong>
                  </p>
                  <hr
                    v-if="empIndex < employee.sucursales?.length - 1"
                    class="black--text"
                    style="border-color: black; border-width: 0.5px"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="employees?.length > employeeLimit"
          cols="12"
          md="4"
          class="mb-10"
        >
          <p>
            Haz llegado al límite de empleados que estan incluidos en tu
            <strong>Plan Básico, PAT</strong>para poder agregar más, contacta a
            tu Asesor PAT.
          </p>
          <v-btn
            rounded
            block
            outlined
            color="primary"
            class="py-6 border-3 text-body-1 font-weight-bold text-none"
            style="border-width: 3px"
            @click="openPatAssistance"
          >
            <v-icon size="28" left>mdi-help-circle-outline</v-icon>
            Asistencia PAT
          </v-btn>
        </v-col>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { getEmpleados } from "@/services/auth.js";
export default {
  data() {
    return {
      // employees: [],
      roles: [
        { id: 1, name: "Super Administrador" },
        { id: 2, name: "Admin" },
        { id: 3, name: "Manager" },
        { id: 4, name: "Supervisor" },
        { id: 5, name: "Almacenista" },
        { id: 6, name: "Cajero" },
      ],
      employees: [],
      employeeLimit: 3,
    };
  },
  computed: {
    isEmployeesEmpty() {
      return this.employees.length === 0;
    },
  },
  methods: {
    SustituirRol(id) {
      if (!id) return "Sin rol";
      return this.roles.find((role) => role.id === id).name || "Sin rol";
    },
    goto(route, id) {
      this.$router.push({ name: route, params: { id: id } });
    },
    goToEdit(id, index) {
      this.$router.push({
        name: "edit-employee",
        params: { id, index },
      });
    },
    addEmployee() {
      this.$router.push({ name: "create-employee" });
    },
    openPatAssistance() {
      console.log("Asistencia PAT...");
    },
  },
  async created() {
    const response = await getEmpleados();
    if(response.success){
      this.employees = response.empleados
      console.log("Empleados", this.employees);
    }else{
      this.$toast({
        message: "Ocurrió un error al obtener las sucursales",
        color: "error",
        timeout: 2000,
      });
      console.log("Error al obtener sucursales");
    }
  }
};
</script>
